import React from "react"

import Layout from "../../components/actiontimer/layout"
import Seo from "../../components/SEO"
import logo from "../../images/actiontimer/applogo.svg"
import seologo from "../../images/actiontimer/appicon256.png"
import featureImage from "../../images/actiontimer/hero_updated.png";
// import thumbnailSystem from "../../images/actiontimer/thumbnail_system.png"
import thumbnailPrinting from "../../images/actiontimer/thumbnail_printing.png"
import thumbnailScreenshot from "../../images/actiontimer/thumbnail_screenshot.png"
import thumbnailFiles from "../../images/actiontimer/thumbnail_files.png"
import thumbnailAcessibility from "../../images/actiontimer/thumbnail_accessibility.png"
import thumbnailTrash from "../../images/actiontimer/thumbnail_trash.png"
import thumbnailMultiple from "../../images/actiontimer/thumbnail_multiple_updated.png"
import thumbnailDarkMode from "../../images/actiontimer/thumbnail_darkmode_updated.png"
import DesktopImg from '../../images/actiontimer/macOS_desktop_updated.png'
import AppstoreBadge from '../../images/actiontimer/Mac_App_Store_Badge_US.svg'
// import PreOrderAppstoreBadge from '../../images/actiontimer/Pre_order_Mac_App_Store_Badge_US.svg'

function IndexPage(props) {
    const seoImage = {
		src: seologo,
		height: 256,
		width: 256,
		alt: "ActionTimer for Mac app logo",
    };
    
    const appStoreCampaignUrlActionTimer = "https://apps.apple.com/app/apple-store/id1518277131?pt=121290356&ct=malaudhome&mt=8"

    return (
    <Layout>
        {/* <SEO title="Action Timer for Mac App"/> */}
        <Seo
            siteTitle={'ActionTimer for Mac'}
            seoTitle="ActionTimer for Mac App"
            path={props.location.pathname}
            metaImage={seoImage}
            icon={seologo}
            description="Schedule and Run simple automation actions with ActionTimer for Mac app"
            keywords={["ActionTimer", "Timer", "Countdown", "Automation", "System Actions", "Mac App Store", "Automate", "Mac Screenshot", "Print Document"]}
            datePublished="09-02-2020"
            dateModified="11-01-2022"
        />

        <div className={"page-header home"}>
            <img className={"applogo"} alt={"Logo"} src={logo}/>
            <h1>ActionTimer</h1>
            {/* <p>For Mac</p> */}
            <h3 className={"subtitle"}>Simple Automation!</h3>
            {/* img app store */}
            {/* <p>Simple Automation!</p> */}
            {/* <p>Run frequent and routine actions at your own convenient time.</p> */}
            <img alt={"ActionTimer Logo"} src={featureImage}/>
            <p>Download on the Mac App Store</p><br /><br />
            <p>
                <a href={appStoreCampaignUrlActionTimer} target={"_blank"} rel="noreferrer" 
                    title={"Download ActionTimer on the Mac App Store"}>
                <img alt={"Download ActionTimer on the Mac App Store"} src={AppstoreBadge}/></a>
            </p>
        </div>

        <div className={"container"}>
            <div className={"features"}>
              <h1 className={"feature-title"}>Features</h1>
        
              <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Capture Screenshots</h2>
                                <p>Capture a screenshot<sup>2</sup> in the background while you're focusing on your work. ActionTimer will save the screenshot in your selected folder. You can schedule multiple screenshots at various times.<br /><br />ActionTimer can also provide a voice announcement and gives you an audio & visual notification before taking a screenshot. So, you always know when a screenshot is being taken.</p>
                            </div>

                            <div className={"store-button"}>                
                                <br />
                                <a href={appStoreCampaignUrlActionTimer} target={"_blank"} rel="noreferrer" 
                                    title={"Download ActionTimer on the Mac App Store"}>
                                <img alt={"Download ActionTimer on the Mac App Store"} src={AppstoreBadge}/></a>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"ActionTimer Screenshot feature"} src={thumbnailScreenshot}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"ActionTimer printing feature"} src={thumbnailPrinting}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Print Documents</h2>
                                <p>Print documents<sup>2</sup> at specified time.<br/>Schedule multiple documents at the various times throughout the day. Productivity has no bounds.</p>
                            </div>
                            <div className={"store-button"}>                
                                <br />
                                <a href={appStoreCampaignUrlActionTimer} target={"_blank"} rel="noreferrer" 
                                    title={"Download ActionTimer on the Mac App Store"}>
                                <img alt={"Download ActionTimer on the Mac App Store"} src={AppstoreBadge}/></a>
                            </div>
                        </div>
                    </div>
                </div>

                 <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Applications and Files</h2>
                                <p>ActionTimer can open Applications<sup>1</sup> and Files<sup>2</sup>, move files and folders to Trash, all without getting in your way. For example, schedule to open Mail.app at the beginning of your day at 9am, schedule to open FaceTime when it's time to talk to your loved one. Unleash your imagination.</p>
                            </div>
                            <div className={"store-button"}>                
                                <br />
                                <a href={appStoreCampaignUrlActionTimer} target={"_blank"} rel="noreferrer" 
                                    title={"Download ActionTimer on the Mac App Store"}>
                                <img alt={"Download ActionTimer on the Mac App Store"} src={AppstoreBadge}/></a>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"ActionTimer Open files feature"} src={thumbnailFiles}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"ActionTimer Move to Trash feature"} src={thumbnailTrash}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Move to Trash</h2>
                                <p>Schedule automatic deletion of files or entire folders<sup>2</sup>. Example workflow: move your done files into a 'to be deleted' folder and schedule that folder for deletion at the end of the day.<br /><br />ActionTimer always moves deleted items to Trash so you can restore the file or folder if you so desire.</p>
                            </div>
                            <div className={"store-button"}>                
                                <br />
                                <a href={appStoreCampaignUrlActionTimer} target={"_blank"} rel="noreferrer" 
                                    title={"Download ActionTimer on the Mac App Store"}>
                                <img alt={"Download ActionTimer on the Mac App Store"} src={AppstoreBadge}/></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                    <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Multiple Actions</h2>
                                <p>Adopt modern automation techniques to enhance productivity at home or at work. Schedule simultaneous multiple timers<sup>4</sup> with automatic actions.<br /><br />
                                Auto compact mode lets you collect multiple timers while saving precious desktop space and help you focus on your more important work.
                                </p>
                            </div>
                            <div className={"store-button"}>                
                                <br />
                                <a href={appStoreCampaignUrlActionTimer} target={"_blank"} rel="noreferrer" 
                                    title={"Download ActionTimer on the Mac App Store"}>
                                <img alt={"Download ActionTimer on the Mac App Store"} src={AppstoreBadge}/></a>
                            </div>

                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"ActionTimer multiple actions"} src={thumbnailMultiple}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"ActionTimer Dark Mode Support"} src={thumbnailDarkMode}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Dark Mode Support</h2>
                                <p>ActionTimer comes with complete support for macOS dark mode with native macOS design. With added support for custom colors, you'll love working with it.</p>
                            </div>
                            <div className={"store-button"}>                
                                <br />
                                <a href={appStoreCampaignUrlActionTimer} target={"_blank"} rel="noreferrer" 
                                    title={"Download ActionTimer on the Mac App Store"}>
                                <img alt={"Download ActionTimer on the Mac App Store"} src={AppstoreBadge}/></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Accessibility</h2>
                                <p>Accessible first design focuses on making software usable for all. ActionTimer supports high contrast, reduced motion, inverse colors, and voice over support. 
                                <br /><br />ActionTimer also has full keyboard support for easy and quick operation.</p>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"ActionTimer Accessibility"} src={thumbnailAcessibility}/>
                            </div>
                        </div>
                    </div>

                    {/* <div>
                <p><img className={"desktop-img"} alt={"macOS_desktop"} src={desktopImg}/></p>
            </div> */}

                </div>
            </div>
            
        </div>

        <div className={"call-to-action"}>
            <div>
                <p>
                    <img className={"desktop-img"} alt={"macOS_desktop"} src={DesktopImg}/>
                </p>
            </div>

            <div className={"container"}>
                <div className={"call-to-action__content"}>
                    <h2>ActionTimer for macOS</h2>
                    <p>Download now on the Mac App Store</p>
                </div>

                <div className={"store-button"}>                
                    <a href={appStoreCampaignUrlActionTimer} target={"_blank"} rel="noreferrer" 
                        title={"Download ActionTimer on the Mac App Store"}>
                    <img alt={"Download ActionTimer on the Mac App Store"} src={AppstoreBadge}/></a>
                </div>

                
            </div>
        </div>
        <div className={"container"}>
            <div className={"footnotes"}>
                <ol>
                <li>
                    The free version of ActionTimer comes with Open Application timer action. Other actions are available for purchase as a bundle via in-app purchase.
                </li>
                <li>
                    Open File, Print PDF document, Move to Trash, Capture Screenshot, and Clear Clipboard actions are part of the Work Bundle and is available for purchase from within the app.
                </li>
                <li>
                    Screen Capture action requires privacy permissions to be able to capture the screenshot.
                </li>
                <li>
                    Free version allows 3 simultaneous timers. Unlock multiple timers with any in-app bundle purchase.
                </li>
                </ol>
            </div>
        </div>
    </Layout>
    )
}

export default IndexPage
