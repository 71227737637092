/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {StaticQuery, graphql, Link} from "gatsby"

// import Header from "./header"
import "../../css/actiontimer/main.scss"
// import logo from "../../images/actiontimer/applogo.svg"

const Layout = ({children}) => (
    <StaticQuery query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `} render={data => (
        <>
            {/* <Header siteTitle={data.site.siteMetadata.title}/> */}
            <main>{children}</main>
            <footer>
                <div className={"container"}>
                    <div className={"row"}>

                        {/* <div className={"col-2"}> */}
                            <div className={"widget__item"}>
                                <div className={"footer-links"}>
                                    <h4>Support</h4>
                                    <ul>
                                        <li><Link to="/actiontimer">ActionTimer app</Link></li>
                                        <li><Link to="/actiontimer/privacy" title={"Privacy Policy"}>ActionTimer Privacy</Link></li>
                                        <li><Link to="/actiontimer/support" title={"Frequently Asked Questions"}>Frequently asked questions</Link></li>
                                        <li><Link to="/contact" title={"Contact Us"}>Contact Us</Link></li>
                                        {/* <li><Link to="/actiontimer/terms" title={"Terms Of Use"}>Terms Of Use</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>

                    <div className={"copyright"}>
                        <p>&copy; {new Date().getFullYear()}, {` `} 
                        <a href="https://malaud.com" style={{textDecoration: 'None', color: 'chocolate'}} title={"Malaud"}>Malaud LLC</a>. All rights reserved.</p>
                    </div>
                    
                </div>

            </footer>
        </>
    )}/>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
